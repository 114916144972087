/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import "../bootstrap/scss/functions";

$light:   #dee2e6;
$blue:    #009de0;
$red:     #f93a25;
$green:   #1c9e0b;
$primary: $blue;
$danger:  $red;
$success: $green;

$border-radius:               .375rem;
$border-radius-sm:            .25rem;
$border-radius-lg:            .5rem;

@import "../bootstrap/scss/variables";
@import "variables";
@import "../bootstrap/scss/mixins";
@import "mixins";
@import "../bootstrap/scss/utilities";

// Layout & components
@import "../bootstrap/scss/root";
@import "../bootstrap/scss/reboot";
@import "../bootstrap/scss/type";
@import "../bootstrap/scss/images";
@import "../bootstrap/scss/containers";
@import "../bootstrap/scss/grid";
@import "../bootstrap/scss/tables";
@import "forms";
@import "../bootstrap/scss/forms";
@import "../bootstrap/scss/buttons";
@import "../bootstrap/scss/transitions";
@import "../bootstrap/scss/dropdown";
@import "dropdown";
@import "../bootstrap/scss/button-group";
@import "../bootstrap/scss/nav";
@import "../bootstrap/scss/navbar";
@import "../bootstrap/scss/card";
@import "card";
@import "../bootstrap/scss/accordion";
@import "../bootstrap/scss/breadcrumb";
@import "../bootstrap/scss/pagination";
@import "../bootstrap/scss/badge";
@import "../bootstrap/scss/alert";
@import "../bootstrap/scss/progress";
@import "../bootstrap/scss/list-group";
@import "../bootstrap/scss/close";
@import "../bootstrap/scss/toasts";
@import "toasts";
@import "../bootstrap/scss/modal";
@import "tooltip";
@import "../bootstrap/scss/tooltip";
@import "../bootstrap/scss/popover";
@import "../bootstrap/scss/carousel";
@import "../bootstrap/scss/spinners";
@import "../bootstrap/scss/offcanvas";
@import "../bootstrap/scss/placeholders";

// Helpers
@import "../bootstrap/scss/helpers";

// Utilities
@import "../bootstrap/scss/utilities/api";
// scss-docs-end import-stack
